module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-119540279-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"en","languages":["en","pt"],"path":"/home/runner/work/landing-page/landing-page/configs/../src/intl","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#ffffff","display":"standalone","icon":"src/assets/images/icon.png","icon_options":{"purpose":"any maskable"},"name":"Panic Lobster","short_name":"Panic Lobster","start_url":"/","theme_color":"#ed008c","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"039c582efb5a66b838964eedfdc9b9fa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
